import Swiper, {
	Navigation, Pagination, Thumbs, FreeMode,
} from 'swiper';

// Slider
function slider(el, config) {
	if (!el) return false;
	const slider = el.querySelector('.swiper');
	if (!slider) return false;
	const next       = el.querySelector('.swiper-button-next');
	const prev       = el.querySelector('.swiper-button-prev');
	const pagination = el.querySelector('.swiper-pagination');
	const settings   = {
		modules               : [Navigation, Pagination, Thumbs, FreeMode],
		loop                  : false,
		slidesPerView         : 'auto',
		spaceBetween          : 0,
		preloadImages         : false,
		watchSlidesVisibility : true,
		watchOverflow         : true,
		threshold             : 10,
		observer              : true,
		navigation            : {
			nextEl : next,
			prevEl : prev,
		},
		pagination : {
			el             : pagination,
			type           : 'bullets',
			clickable      : true,
			dynamicBullets : false,
		},
	};

	if (config) {
		Object.assign(settings, config);
	}

	return new Swiper(slider, settings);
}

const sliderBuilder = document.querySelectorAll('[data-slider="builder-slider"]');
sliderBuilder.forEach((item) => slider(item, {
	loop                : false,
	watchSlidesProgress : true,
	freeMode            : true,
	slidesPerView       : 'auto',
	breakpoints         : {
		1038 : {
			allowTouchMove : false,
		},
	},
}));

const sliderServices = document.querySelectorAll('[data-slider="services-slider"]');
sliderServices.forEach((item) => slider(item, {
	loop                : false,
	watchSlidesProgress : true,
	freeMode            : true,
	slidesPerView       : 'auto',
	breakpoints         : {
		744 : {
			allowTouchMove : false,
		},
	},
}));

const sliderHelp = document.querySelectorAll('[data-slider="help-slider"]');
sliderHelp.forEach((item) => slider(item, {
	loop                : false,
	watchSlidesProgress : true,
	freeMode            : true,
	slidesPerView       : 'auto',
	breakpoints         : {
		744 : {
			allowTouchMove : false,
		},
	},
}));

const sliderBlog = document.querySelectorAll('[data-slider="blog-slider"]');
sliderBlog.forEach((item) => slider(item, {
	loop                : false,
	watchSlidesProgress : true,
	slidesPerView       : 'auto',
	breakpoints         : {
		744 : {
			allowTouchMove : false,
		},
	},
}));

const sliderLearn = document.querySelectorAll('[data-slider="learn-slider"]');
sliderLearn.forEach((item) => slider(item, {
	loop                : false,
	watchSlidesProgress : true,
	slidesPerView       : 'auto',
	breakpoints         : {
		744 : {
			allowTouchMove : false,
		},
	},
}));

const sliderHighlights = document.querySelectorAll('[data-slider="Highlights-slider"]');
sliderHighlights.forEach((item) => slider(item, {
	loop                : false,
	watchSlidesProgress : true,
	slidesPerView       : 'auto',
	freeMode            : true,
	breakpoints         : {
		744 : {
			allowTouchMove : false,
		},
	},
}));

const sliderHighlight = document.querySelectorAll('[data-slider="Highlight-slider"]');
sliderHighlight.forEach((item) => slider(item, {
	loop          : false,
	slidesPerView : 'auto',
	navigation    : {
		nextEl : '.highlight-slider-next',
		prevEl : '.highlight-slider-prev',
	},
}));
